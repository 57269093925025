import React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from '@mui/material';
import colors from 'assets/theme/base/colors';

export default function ReactDataTable({ data }) {
    const columns = [
        { field: "id", headerName: "ID", width: 150 },
        { field: "isin", headerName: "ISIN", width: 250 },
        { field: "buy_date", headerName: "Buy Date", width: 250 },
        { field: "sell_date", headerName: "Sell Date", width: 250 },
        { field: "buy_amount", headerName: "Buy Amount", width: 300 },
        { field: "sell_amount", headerName: "Sell Amount", width: 100 },
    ];

    const paginationModel = { page: 0, pageSize: 6 };
    
    return (
        data.length > 0 ? (
            <DataGrid
                rows={data}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                disableRowSelectionOnClick
                disableColumnSorting
                disableColumnMenu
                disableColumnResize
                density='compact'
                sx={{
                    border: 'none',
                    color: colors.text.main,
                    "*::-webkit-scrollbar": {
                        display: "none",
                    },
                    ".MuiDataGrid-columnHeaders": {
                        fontSize: '.75rem',
                    },
                    ".MuiDataGrid-row": {
                        color: "#b2b9bf",
                        fontSize: ".85rem"
                    }
                }}
            />
        ) : (
            <Typography sx={{ color: colors.text.main, fontSize: "1rem", height: '19.45rem', display: 'flex', justifyContent: "center", alignItems: "center" }}>No data available.</Typography>
        )
    );
}
