import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

// Create a Context
export const AppContext = createContext();

// Create a Provider Component
export const AppProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const verifyUser = async () => {
            try {
                const accessToken = Cookies.get('access-token');
                const userId = Cookies.get('user_id');

                if (accessToken && userId) {
                    const response = await fetch('http://localhost:5000/api/auth/verify', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });

                    if (response.ok) {
                        const userData = await response.json();
                        setCurrentUser(userData);
                    } else {
                        // If verification fails, clear cookies and user state
                        Cookies.remove('access-token');
                        Cookies.remove('refresh-token');
                        Cookies.remove('user_id');
                        setCurrentUser(null);
                    }
                }
            } catch (error) {
                console.error('Verification error:', error);
                setCurrentUser(null);
            } finally {
                setIsLoading(false);
            }
        };

        verifyUser();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Or your loading component
    }

    return (
        <AppContext.Provider value={{ currentUser, setCurrentUser }}>
            {children}
        </AppContext.Provider>
    );
};

// export default AppProvider;
