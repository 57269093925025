import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "../../../assets/images/trading/login_bg.jpg";
import { useVerifyEmailMutation, useResendVerificationMutation } from "features/user/userSlice";

function VerifyEmail() {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  useEffect(() => {
    // Redirect if no email is present
    if (!email) {
      navigate('/authentication/sign-up');
    }
  }, [email, navigate]);

  const [verifyEmail] = useVerifyEmailMutation();
  const [resendVerification] = useResendVerificationMutation();

  const handleVerification = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const result = await verifyEmail({
        email,
        code: verificationCode
      }).unwrap();

      if (result.success) {
        setError('Email verified successfully! Redirecting to login...');
        
        setTimeout(() => {
          navigate('/authentication/sign-in', { 
            state: { message: 'Email verified successfully. Please login.' }
          });
        }, 1500);
      }
    } catch (err) {
      setError(err.data?.message || 'Verification failed');
    }
  };

  const handleResendCode = async () => {
    try {
      await resendVerification({ email }).unwrap();
      setError('New verification code has been sent to your email');
    } catch (err) {
      setError(err.data?.message || 'Failed to resend verification code');
    }
  };

  return (
    <IllustrationLayout
      title="Verify Your Email"
      description="Please enter the verification code sent to your email"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Verification Code"
            fullWidth
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </MDBox>
        {error && (
          <MDTypography variant="caption" color="error" fontSize={12}>
            {error}
          </MDTypography>
        )}
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" fullWidth onClick={handleVerification}>
            Verify Email
          </MDButton>
        </MDBox>
        <MDBox mt={2} textAlign="center">
          <MDTypography
            variant="button"
            color="info"
            fontWeight="medium"
            onClick={handleResendCode}
            sx={{ cursor: "pointer" }}
          >
            Resend Verification Code
          </MDTypography>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default VerifyEmail;
