import { ResponsiveCalendar } from "@nivo/calendar";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";

function Calendar({ data }) {
    const darkModeContext = useMaterialUIController();
    const isDarkMode = darkModeContext[0].darkMode;



    const dates = data.map(item => new Date(item.day));
    const minDate = dates.length ? new Date(Math.min(...dates)) : new Date();
    const maxDate = dates.length ? new Date(Math.max(...dates)) : new Date();

    // Format dates as YYYY-MM-DD
    const fromDate = minDate.toISOString().split('T')[0];
    const toDate = maxDate.toISOString().split('T')[0];

    const customTooltip = (data) => {
        const value = data?.value ? Number(data.value) : 0;
        return (
            <div style={{
                background: isDarkMode ? '#1a1a1a' : 'white',
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                color: isDarkMode ? 'white' : 'black'
            }}>
                <strong>{data.day}</strong>
                <br />
                <span style={{ color: value >= 0 ? '#4CAF50' : '#ff4d4d' }}>
                    ₹{value.toFixed(2)}
                </span>
            </div>
        );
    };

    return (
        <MDBox sx={{ height: "50rem" }}>
            <ResponsiveCalendar
                theme={{ textColor: isDarkMode ? "#FFF" : "#000" }}
                data={data}
                from={fromDate}
                to={toDate}
                emptyColor={isDarkMode ? "#202940" : "#eeeeee"}
                colors={["#ff4d4d", "#4CAF50"]}
                minValue={-1}
                maxValue={1}
                valueFormat=".2f"
                margin={{
                    top: 40,
                    right: 20,
                    bottom: 40,
                    left: 20,
                }}
                yearSpacing={20}
                monthBorderColor={isDarkMode ? "#202940" : "#ffffff"}
                monthSpacing={5}
                monthLegendOffset={10}
                dayBorderWidth={1}
                dayBorderColor={isDarkMode ? "#202940" : "#ffffff"}
                daySpacing={2}
                tooltip={customTooltip}
                legends={[
                    {
                        anchor: "bottom-right",
                        direction: "row",
                        translateY: 36,
                        itemCount: 2,
                        itemWidth: 60,
                        itemHeight: 20,
                        itemsSpacing: 8,
                        itemDirection: "left-to-right",
                        items: [
                            {
                                id: 'loss',
                                label: 'Loss',
                                color: "#ff4d4d"
                            },
                            {
                                id: 'profit',
                                label: 'Profit',
                                color: "#4CAF50"
                            }
                        ]
                    }
                ]}
            />
        </MDBox>
    )
}

export default Calendar;