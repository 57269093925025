import React from 'react';
import { ButtonGroup, Button, Tooltip } from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const DateRangeSelector = ({ selectedRange, onRangeChange }) => {
  const ranges = [
    { label: '1D', value: '1d', tooltip: 'Last 24 hours' },
    { label: '1W', value: '1w', tooltip: 'Last 7 days' },
    { label: '1M', value: '1m', tooltip: 'Last 30 days' },
    { label: '3M', value: '3m', tooltip: 'Last 3 months' },
    { label: '6M', value: '6m', tooltip: 'Last 6 months' },
    { label: '1Y', value: '1y', tooltip: 'Last 12 months' },
    { label: 'ALL', value: 'all', tooltip: 'All time data' }
  ];

  return (
    <MDBox 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2,
        backgroundColor: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        padding: '8px 12px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
      }}
    >
      <MDTypography variant="caption" fontWeight="medium" color="text">
        Time Range:
      </MDTypography>
      <ButtonGroup size="small" aria-label="date range selector">
        {ranges.map((range) => (
          <Tooltip key={range.value} title={range.tooltip} arrow placement="bottom">
            <Button
              onClick={() => onRangeChange(range.value)}
              variant={selectedRange === range.value ? 'contained' : 'outlined'}
              color="info"
              sx={{
                minWidth: '45px',
                fontSize: '0.75rem',
                fontWeight: selectedRange === range.value ? 'bold' : 'medium',
                '&.MuiButton-outlined': {
                  borderColor: (theme) => theme.palette.grey[300],
                  color: (theme) => theme.palette.text.primary,
                }
              }}
            >
              {range.label}
            </Button>
          </Tooltip>
        ))}
      </ButtonGroup>
    </MDBox>
  );
};

export default DateRangeSelector;