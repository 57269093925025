import React, { useContext, useEffect, useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Container,
    Grid,
    Card,
    CardContent,
    Box,
    // useTheme,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import InsightsIcon from '@mui/icons-material/Insights';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import { ThemeProvider } from '@mui/material/styles';
import bgImage from '../../assets/images/trading/trading_bg.jpg';
// import theme from './theme';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "components/context/AppContext";
import Cookies from "js-cookie";

// const pulse = keyframes`
//   0% {
//     box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.7);
//   }
//   70% {
//     box-shadow: 0 0 0 10px rgba(0, 188, 212, 0);
//   }
//   100% {
//     box-shadow: 0 0 0 0 rgba(0, 188, 212, 0);
//   }
// `;

const HeroSection = styled('div')(({ theme }) => ({
    background: `linear-gradient(45deg, ${theme.palette.background.default} 30%, ${theme.palette.background.paper} 90%)`,
    color: theme.palette.text.primary,
    height: '95vh',
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    padding: theme.spacing(15, 0, 6),
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `url(${bgImage})`,
        backgroundSize: 'cover',
        opacity: 0.1,
        zIndex: 0,
    },
}));

const FeatureIcon = styled('div')(({ theme }) => ({
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    // animation: `${pulse} 2s infinite`,
}));

const GlowingButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: -4,
        left: -4,
        right: -4,
        bottom: -4,
        background: theme.palette.primary.main,
        zIndex: -1,
        filter: 'blur(8px)',
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out',
    },
    '&:hover::before': {
        opacity: 0.7,
    },
}));

const LandingPage = () => {
    // const theme = useTheme();
    const navigate = useNavigate();
    const { currentUser } = useContext(AppContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Add useEffect to check authentication status
    useEffect(() => {
        const checkAuth = () => {
            const accessToken = Cookies.get('access-token');
            const userId = Cookies.get('user_id');
            setIsAuthenticated(!!(accessToken && userId));
        };

        checkAuth();
    }, [currentUser]);

    return (
        <>
            <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                        Trading Journal
                    </Typography>
                    <Button color="inherit">Features</Button>
                    <Button color="inherit">Pricing</Button>
                    <Button color="inherit">About</Button>
                    {isAuthenticated ? (
                        <GlowingButton 
                            variant="contained" 
                            color="secondary" 
                            sx={{ backgroundColor: '#5859d7', color: 'secondary.contrastText' }}
                            onClick={() => navigate('/dashboards/sales')}
                        >
                            Dashboard
                        </GlowingButton>
                    ) : (
                        <GlowingButton 
                            variant="contained" 
                            color="secondary" 
                            sx={{ backgroundColor: '#5859d7', color: 'secondary.contrastText' }}
                            onClick={() => navigate('/authentication/sign-in')}
                        >
                            Login
                        </GlowingButton>
                    )}
                </Toolbar>
            </AppBar>

            <HeroSection>
                <Container sx={{ position: 'relative', zIndex: 1, textAlign: 'center' }}>
                    <Typography
                        variant="h1"
                        component="h1"
                        gutterBottom
                        sx={{
                            // fontWeight: 'bold',
                            color: '#fff',
                            fontSize: '5rem',
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        Track Your Trades
                    </Typography>
                    <Typography
                        variant="h5"
                        paragraph
                        sx={{
                            color: '#fff',
                            position: 'relative',
                            zIndex: 1
                        }}
                    >
                        Analyze, Track, Audit and Improve Your Trades with our tools.   
                    </Typography>
                    <GlowingButton variant="contained" color="secondary" size="large" sx={{ backgroundColor: 'secondary.main', color: 'secondary.contrastText' }}
                        onClick={() => navigate('/authentication/sign-up')}
                    >
                        Get Started
                    </GlowingButton>
                </Container>
            </HeroSection>

            <Container sx={{ py: 8, height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardContent>
                                <FeatureIcon>
                                    <TrendingUpIcon fontSize="inherit" />
                                </FeatureIcon>
                                <Typography variant="h5" component="div" gutterBottom>
                                    Performance Tracking
                                </Typography>
                                <Typography variant="body2">
                                    Monitor your trading performance with advanced analytics and visualizations.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardContent>
                                <FeatureIcon>
                                    <InsightsIcon fontSize="inherit" />
                                </FeatureIcon>
                                <Typography variant="h5" component="div" gutterBottom>
                                    Insightful Analytics
                                </Typography>
                                <Typography variant="body2">
                                    Gain deep insights into your trading patterns and behaviors.
                                    <br />
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardContent>
                                <FeatureIcon>
                                    <ShowChartIcon fontSize="inherit" />
                                </FeatureIcon>
                                <Typography variant="h5" component="div" gutterBottom>
                                    Trade Journal
                                </Typography>
                                <Typography variant="body2">
                                    Keep a detailed record of all your trades with easy-to-use journaling tools.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card>
                            <CardContent>
                                <FeatureIcon>
                                    <PieChartIcon fontSize="inherit" />
                                </FeatureIcon>
                                <Typography variant="h5" component="div" gutterBottom>
                                    Portfolio Analysis
                                </Typography>
                                <Typography variant="body2">
                                    Analyze your portfolio allocation and risk exposure.
                                    <br />
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
                <Container>
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Trusted by Traders Worldwide
                    </Typography>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h2" align="center" color="primary" sx={{ fontWeight: 'bold' }}>
                                10k+
                            </Typography>
                            <Typography variant="subtitle1" align="center">
                                Active Users
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h2" align="center" color="primary" sx={{ fontWeight: 'bold' }}>
                                1M+
                            </Typography>
                            <Typography variant="subtitle1" align="center">
                                Trades Analyzed
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h2" align="center" color="primary" sx={{ fontWeight: 'bold' }}>
                                95%
                            </Typography>
                            <Typography variant="subtitle1" align="center">
                                User Satisfaction
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{ bgcolor: 'background.default', color: 'primary.contrastText', py: 12, backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'start' }}>
                <Container>
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
                        Ready to Take Your Trading to the Next Level?
                    </Typography>
                    <Box display="flex" justifyContent="center" mt={4}>
                        <GlowingButton variant="contained" color="secondary" size="large" sx={{ backgroundColor: 'secondary.main', color: 'secondary.contrastText' }}
                            onClick={() => navigate('/authentication/sign-up')}
                        >
                            Sign Up Now
                        </GlowingButton>
                    </Box>
                </Container>
            </Box>

            <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
                <Container maxWidth="lg">
                    <Typography variant="body2" color="text.secondary" align="center">
                        © 2023 Trading Journal. All rights reserved.
                    </Typography>
                </Container>
            </Box>
        </>
    );
};


export default LandingPage;

