import React from 'react';
import { ResponsiveSankey } from '@nivo/sankey';
import './ShankyNivoChart.css'; // Import the CSS file
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import PropTypes from 'prop-types';

export default function ShankyNivoChart({ tradingCharges }) {
  // Transform API data into Sankey format
  console.log("tradingCharges sknn", tradingCharges)
  const transformData = (tradingData) => {
    const data = Array.isArray(tradingData) ? tradingData : [tradingData];
    
    if (!data.length) {
      return null;
    }

    const totals = data.reduce((acc, item) => ({
      total: acc.total + (item.total || 0),
      brokerage: acc.brokerage + (item.brokerage || 0),
      gst: acc.gst + (item.gst || 0),
      stt: acc.stt + (item.stt || 0),
      stampDuty: acc.stampDuty + (item.stampDuty || 0),
      transaction: acc.transaction + (item.transaction || 0),
      clearing: acc.clearing + (item.clearing || 0),
      ipft: acc.ipft + (item.ipft || 0),
      others: acc.others + (item.others || 0),
      sebiTurnover: acc.sebiTurnover + (item.sebiTurnover || 0)
    }), {
      total: 0, brokerage: 0, gst: 0, stt: 0, stampDuty: 0,
      transaction: 0, clearing: 0, ipft: 0, others: 0, sebiTurnover: 0
    });

    // Calculate grouped charges
    const regulatoryCharges = totals.stt + totals.stampDuty + totals.sebiTurnover;
    const exchangeCharges = totals.transaction + totals.clearing + totals.ipft;
    const otherCharges = totals.gst + totals.others;

    const sankeyData = {
      nodes: [
        // Level 1
        { id: "Total Charges", nodeColor: "hsl(247, 70%, 50%)" },
        
        // Level 2 - Main Categories
        { id: "Trading Charges", nodeColor: "hsl(157, 70%, 50%)" },
        { id: "Additional Charges", nodeColor: "hsl(180, 70%, 50%)" },
        
        // Level 3 - Detailed Categories
        { id: "Brokerage", nodeColor: "hsl(157, 70%, 50%)" },
        { id: "Regulatory Charges", nodeColor: "hsl(260, 70%, 50%)" },
        { id: "Exchange Charges", nodeColor: "hsl(180, 70%, 50%)" },
        { id: "Other Charges", nodeColor: "hsl(147, 70%, 50%)" }
      ],
      links: [
        // Level 1 to Level 2
        { source: "Total Charges", target: "Trading Charges", value: totals.brokerage },
        { source: "Total Charges", target: "Additional Charges", value: regulatoryCharges + exchangeCharges + otherCharges },
        
        // Level 2 to Level 3
        { source: "Trading Charges", target: "Brokerage", value: totals.brokerage },
        { source: "Additional Charges", target: "Regulatory Charges", value: regulatoryCharges },
        { source: "Additional Charges", target: "Exchange Charges", value: exchangeCharges },
        { source: "Additional Charges", target: "Other Charges", value: otherCharges }
      ]
    };

    return sankeyData;
  };

  // console.log('Received tradingCharges prop:', tradingCharges);
  const sankeyData = tradingCharges ? transformData(tradingCharges) : null;

  if (!sankeyData) return <MDTypography>Loading charges data...</MDTypography>;

  return (
    <div style={{ width: '100%', height: '20rem' }}>
      <ResponsiveSankey
        data={sankeyData}
        margin={{ top: 2, right: 2, bottom: 90, left: 2 }}
        align="justify"
        colors={{ scheme: 'category10' }}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.35}
        nodeThickness={12}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{
          from: 'color',
          modifiers: [['darker', '0.8']]
        }}
        nodeBorderRadius={3}
        linkOpacity={0.6}
        linkHoverOthersOpacity={0.1}
        linkContract={1}
        enableLinkGradient={true}
        labelPosition="outside"
        labelOrientation="horizontal"
        legends={[]}
      />
      <CustomLegend nodes={sankeyData.nodes} />
    </div>
  );
}

// PropTypes validation
ShankyNivoChart.propTypes = {
  tradingCharges: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};

const CustomLegend = ({ nodes }) => (
  <div className="custom-legend">
    {nodes.map(node => (
      <MDBox variant="h6" key={node.id} className="legend-item" sx={{
        display: "flex",
        alignItems: "center",
        margin: " 5px 10px",
        fontSize: 11,
        color: "#b2b9bf"
      }}>
        <span className="legend-color" style={{ backgroundColor: node.nodeColor }}></span>
        {node.id}
      </MDBox>
    ))}
  </div >
);