import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from 'js-cookie';



export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            const token = Cookies.get('access-token');
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        saveSignUp: builder.mutation({
            query: (credentials) => ({
                url: '/auth/signup',
                method: 'POST',
                body: credentials,
                headers: {
                    "Content-type": "application/json",
                },
            }),
        }),
        getSignOut: builder.query({
            query: (getDataURL) => getDataURL,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
        }),
        getCurrentUser: builder.query({
            query: (getDataURL) => getDataURL,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
        }),
        getUpstoxAppConfiguration: builder.query({
            query: (getDataURL) => getDataURL,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
        }),
        signIn: builder.mutation({
            query: (credentials) => ({
                url: '/auth/login',
                method: 'POST',
                body: credentials,
                headers: {
                    "Content-type": "application/json",
                },
                
            }),
            transformResponse: (response) => response,
        }),
        verifyEmail: builder.mutation({
            query: (credentials) => ({
                url: '/auth/verify-email',
                method: 'POST',
                body: credentials,
            }),
        }),
        resendVerification: builder.mutation({
            query: (credentials) => ({
                url: '/auth/resend-verification',
                method: 'POST',
                body: credentials,
            }),
        }),
        requestPasswordReset: builder.mutation({
            query: (credentials) => ({
                url: '/auth/request-reset',
                method: 'POST',
                body: credentials,
            }),
        }),
        resetPassword: builder.mutation({
            query: (credentials) => ({
                url: '/auth/reset-password',
                method: 'POST',
                body: credentials,
            }),
        }),
    }),
});

export const {
    useGetSignInQuery,
    useGetCurrentUserQuery,
    useGetUpstoxAppConfigurationQuery,
    useGetSignOutQuery,
    useSaveSignUpMutation,
    useSignInMutation,
    useVerifyEmailMutation,
    useResendVerificationMutation,
    useRequestPasswordResetMutation,
    useResetPasswordMutation
} = userApi;









































// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   currentUser: null,
//   error: null,
//   loading: false,
// };

// const userSlice = createSlice({
//   name: 'user',
//   initialState,
//   reducers: {
//     signInStart: (state) => {
//       state.loading = true;
//       state.error = null;
//     },
//     signInSuccess: (state, action) => {
//       state.currentUser = action.payload;
//       state.loading = false;
//       state.error = null;
//     },
//     signInFailure: (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//     },
//     updateStart: (state) => {
//       state.loading = true;
//       state.error = null;
//     },
//     updateSuccess: (state, action) => {
//       state.currentUser = action.payload;
//       state.loading = false;
//       state.error = null;
//     },
//     updateFailure: (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//     },
//     deleteUserStart: (state) => {
//       state.loading = true;
//       state.error = null;
//     },
//     deleteUserSuccess: (state) => {
//       state.currentUser = null;
//       state.loading = false;
//       state.error = null;
//     },
//     deleteUserFailure: (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//     },
//     signoutSuccess: (state) => {
//       state.currentUser = null;
//       state.error = null;
//       state.loading = false;
//     },
//   },
// });

// export const {
//   signInStart,
//   signInSuccess,
//   signInFailure,
//   updateStart,
//   updateSuccess,
//   updateFailure,
//   deleteUserStart,
//   deleteUserSuccess,
//   deleteUserFailure,
//   signoutSuccess,
// } = userSlice.actions;

// export default userSlice.reducer;