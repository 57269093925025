import { configureStore } from "@reduxjs/toolkit";
import { tradingApi } from "./tradingApiSlice";
import { userApi } from "./user/userSlice";

export const store = configureStore({
    reducer: {
        [tradingApi.reducerPath]: tradingApi.reducer,
        [userApi.reducerPath]: userApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            tradingApi.middleware,
            userApi.middleware
        ),
});

