import React, { useState, useEffect } from 'react';
import { Drawer, TextField, Box } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

function AppConfiguration({ provider, onClose }) {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState({
        provider: provider || '',
        apiKey: '',
        apiSecret: '',
    });
    const [showApiKey, setShowApiKey] = useState(false);
    const [showApiSecret, setShowApiSecret] = useState(false);

    useEffect(() => {
        const fetchExistingConfig = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/upstox/getUpstoxAppConfiguration?userId=${Cookies.get('user_id')}`, {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('access-token')}`
                    }
                });
                const data = await response.json();
                
                if (response.ok && data.configuration) {
                    setConfig({
                        provider: provider,
                        apiKey: data.configuration.appId || '',
                        apiSecret: data.configuration.appSecret || ''
                    });
                } else {
                    setConfig({
                        provider: provider,
                        apiKey: '',
                        apiSecret: ''
                    });
                }
            } catch (error) {
                console.error('Error fetching configuration:', error);
                enqueueSnackbar('Error loading configuration', { variant: 'error' });
                setConfig({
                    provider: provider,
                    apiKey: '',
                    apiSecret: ''
                });
            } finally {
                setLoading(false);
            }
        };

        fetchExistingConfig();
    }, [provider, enqueueSnackbar]);

    const handleChange = (event) => {
        setConfig({
            ...config,
            [event.target.name]: event.target.value,
        });
    };

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    const handleSaveAppConfiguration = async () => {
        // Add validation for empty fields
        if (!config.apiKey || !config.apiSecret || !config.apiKey.trim() || !config.apiSecret.trim()) {
            enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
            return;
        }

        try {
            const response = await fetch('http:///api/upstox/saveUpstoxAppConfiguration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('access-token')}`
                },
                body: JSON.stringify({
                    userId: Cookies.get('user_id'),
                    config
                }),
            });

            const data = await response.json();
            if (response.ok && data.authUrl) {
                enqueueSnackbar('Configuration saved successfully!', { variant: 'success' });
                try {
                    const authWindow = window.open(data.authUrl, '_blank');
                    if (!authWindow) {
                        enqueueSnackbar('Please allow popups to complete the authentication', { variant: 'warning' });
                    }
                } catch (windowError) {
                    enqueueSnackbar('Error opening authentication window', { variant: 'error' });
                }
            } else {
                if (data.error === "INVALID_CREDENTIALS") {
                    enqueueSnackbar('Invalid API credentials. Please check your API Key and Secret.', {
                        variant: 'error',
                        autoHideDuration: 5000
                    });
                } else {
                    enqueueSnackbar(data.message || 'Failed to save configuration', { variant: 'error' });
                }
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Error saving configuration', { variant: 'error' });
        }
    };

    if (loading) {
        return (
            <Drawer
                anchor="bottom"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: '100%',
                        bgcolor: theme.palette.background.default,
                        [theme.breakpoints.up('sm')]: {
                            width: '98%',
                        }
                    }
                }}
            >
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '300px' 
                }}>
                    <CircularProgress />
                </Box>
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: '100%',
                    bgcolor: theme.palette.background.default,
                    [theme.breakpoints.up('sm')]: {
                        width: '98%',
                    }
                }
            }}
        >
            <Box sx={{
                p: { xs: 2, sm: 3 },
                width: '100%',
                minHeight: { xs: '100vh', sm: '300px' },
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                color: theme.palette.text.primary
            }}>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 18,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <MDTypography variant="h6" mb={3}>App Configuration</MDTypography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: { xs: '90%', sm: '70%', md: '50%' },
                }}>
                    <TextField
                        fullWidth
                        label="Provider"
                        name="provider"
                        value={config.provider}
                        onChange={handleChange}
                        margin="normal"
                        disabled
                    />
                    <Box sx={{ position: 'relative' }}>
                        <TextField
                            fullWidth
                            label="API Key"
                            name="apiKey"
                            value={config.apiKey}
                            onChange={handleChange}
                            type={showApiKey ? 'text' : 'password'}
                            margin="normal"
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: theme.palette.text.primary,
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.text.secondary,
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                },
                            }}
                        />
                        <IconButton
                            onClick={() => setShowApiKey(!showApiKey)}
                            sx={{
                                position: 'absolute',
                                right: 10,
                                top: '50%',
                                transform: 'translateY(-50%)',
                            }}
                        >
                            {showApiKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                        <TextField
                            fullWidth
                            label="API Secret"
                            name="apiSecret"
                            value={config.apiSecret}
                            onChange={handleChange}
                            type={showApiSecret ? 'text' : 'password'}
                            margin="normal"
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: theme.palette.text.primary,
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.text.secondary,
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.divider,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                },
                            }}
                        />
                        <IconButton
                            onClick={() => setShowApiSecret(!showApiSecret)}
                            sx={{
                                position: 'absolute',
                                right: 10,
                                top: '50%',
                                transform: 'translateY(-50%)',
                            }}
                        >
                            {showApiSecret ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </Box>
                    <MDTypography variant="caption" color="text" sx={{ mt: 2 }}>
                        Add below Redirect URL to your Upstox App:
                        <Box component="code" sx={{
                            display: 'block',
                            bgcolor: theme.palette.mode === 'dark' ? 'grey.900' : 'grey.100',
                            color: theme.palette.mode === 'dark' ? 'grey.300' : 'grey.900',
                            p: 1,
                            mt: 1,
                            borderRadius: 1
                        }}>
                            https://trading.com/api/broker/upstox/login
                        </Box>
                    </MDTypography>
                    <MDTypography 
                        variant="caption" 
                        color="text" 
                        sx={{ 
                            mt: 1,
                            textAlign: { xs: 'center', sm: 'left' }
                        }}
                    >
                        For security reasons, we do not store user data including API keys and secrets on our servers.<br />
                        This means that you will have to reconnect to your broker every time you start a new session.
                    </MDTypography>
                </Box>
                <Box sx={{ mt: 2, mb: { xs: 4, sm: 2 } }}>
                    <MDButton 
                        variant="contained" 
                        color="success" 
                        onClick={handleSaveAppConfiguration}
                        sx={{
                            px: { xs: 4, sm: 6 },
                            py: { xs: 1, sm: 1.5 }
                        }}
                    >
                        {`Connect ${config.provider}`}
                    </MDButton>
                </Box>
            </Box>
        </Drawer>
    );
}

export default AppConfiguration;
