import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from './context/AppContext';

const ProtectedRoute = ({ children }) => {
  const { currentUser, isLoading } = useContext(AppContext);

  // Show loading state while checking authentication
  if (isLoading) {
    return <div>Loading...</div>; // You can replace this with a proper loading component
  }

  // Redirect to sign-in if not authenticated
  if (!currentUser) {
    return <Navigate to="/authentication/sign-in/illustration" />;
  }

  // Render the protected content if authenticated
  return children;
};

export default ProtectedRoute; 