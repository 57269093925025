// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { ResponsivePie } from '@nivo/pie'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
// const myData = [
//     {
//         "id": "elixir",
//         "label": "elixir",
//         "value": 32,
//         // "color": "hsl(249, 70%, 50%)"
//     },
//     {
//         "id": "c",
//         "label": "c",
//         "value": 249,
//         // "color": "hsl(114, 70%, 50%)"
//     },
//     {
//         "id": "css",
//         "label": "css",
//         "value": 126,
//         // "color": "hsl(114, 70%, 50%)"
//     },

// ]

const NivoHalfDoughnutChart = ({ myData }) => (
    <ResponsivePie
        data={myData}
        margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
        startAngle={-90}
        endAngle={90}
        innerRadius={0.7}
        activeOuterRadiusOffset={2}
        colors={{ scheme: "set1" }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={1.5}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsRadiusOffset={0}
        arcLabelsSkipAngle={10}

    />
)



export default NivoHalfDoughnutChart;