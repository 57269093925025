// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie
import { ResponsivePieCanvas } from '@nivo/pie'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const NivoPieChart = ({ data, innerRadius, legend }) => (
    <ResponsivePieCanvas
        data={data}
        margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
        // margin={{ top: 40, right: 200, bottom: 40, left: 80 }}
        pixelRatio={2}
        innerRadius={innerRadius}
        activeOuterRadiusOffset={2}
        colors={{ scheme: "set1" }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsRadiusOffset={2}
        legends={
            [
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: -200,
                    translateY: 38,
                    itemWidth: 63,
                    itemHeight: 45,
                    itemsSpacing: 0,
                    symbolSize: 12,
                    itemDirection: 'right-to-left'
                }
            ]
        }
    />
)


export default NivoPieChart