import React from 'react';
import { Box, Card } from '@mui/material';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

function DataSelector({ onSelectDataType }) {
    return (
        <Card>
            <Box
                sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                <MDTypography variant="h5">Select Data Type</MDTypography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <MDButton
                        variant="contained"
                        color="info"
                        onClick={() => onSelectDataType('dummy')}
                    >
                        Dummy Data
                    </MDButton>
                    <MDButton
                        variant="contained"
                        color="success"
                        onClick={() => onSelectDataType('real')}
                    >
                        Real Data
                    </MDButton>
                </Box>
            </Box>
        </Card>
    );
}

export default DataSelector; 