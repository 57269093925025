/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import channelChartData from "layouts/dashboards/sales/components/ChannelsChart/data";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import NivoPieChart from "components/nivoCharts/pieChart/NivoPieChart";
import ShankyNivoChart from "components/nivoCharts/ShankyNivoChart/ShankyNivoChart";

function ShankyChart({ tradingCharges }) {
    console.log("tradingCharges in ShankyChart" , tradingCharges)
    return (
        <Card sx={{ height: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" direction="row" pt={2} px={2}>
                <MDTypography variant="h6">Trade Charges</MDTypography>
            </MDBox>
            <MDBox mt={3} >
                <Grid container>
                    <Grid item sx={{ width: '95%', height: "19rem", paddingLeft: '2rem', paddingRight: "2rem" }}>
                        <ShankyNivoChart tradingCharges={tradingCharges} />
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default ShankyChart;
