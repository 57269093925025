/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import InsertChartIcon from "@mui/icons-material/InsertChart";
import { Icon, IconButton } from "@mui/material";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
// import defaultDoughnutChartData from "layouts/pages/charts/data/defaultDoughnutChartData";
import { tradeDoughnutChartData } from "layouts/pages/charts/data/defaultDoughnutChartData";
import NivoPieChart from "components/nivoCharts/pieChart/NivoPieChart";
import NivoHalfDoughnutChart from "components/nivoCharts/halfPieChart/NivoHalfDoughnutChart";
// import { RadialChart } from "components/shadcnCharts/RadialChart";
// Material Dashboard 2 PRO React contexts

// // custom data for nivo pie chart
// const myData = [
//   {
//       "id": "c",
//       "label": "c",
//       "value": 249,
//       "color": "hsl(114, 70%, 50%)"
//   },
//   {
//       "id": "css",
//       "label": "css",
//       "value": 126,
//       "color": "hsl(114, 70%, 50%)"
//   },

// ]

function DefaultStatisticsCard({ title, count, myData, tradeWinPercentage, tradeExpectancy }) {
  return (
    <Card>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={7}>
            <MDBox mb={0.2} lineHeight={1}>
              <MDTypography
                variant="button"
                fontWeight="500"
                color="text"
                fontSize=".6em"
                textTransform="capitalize"
              >
                {title}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography variant="h6">{count}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={5} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <MDBox>
              {title === "Net P&L" && (
                <IconButton>
                  <Icon color="info">insert_chart_icon</Icon>
                </IconButton>
              )}
              {title === "Trade Expectancy" && (
                <MDBox sx={{ width: '50px', height: "50px" }}>
                  {/* waterfall chart icon */}
                  <IconButton>
                    <Icon color="info">waterfall_chart_icon</Icon>
                  </IconButton>
                </MDBox>
              )}
              {title === "Profit Factor" &&
                <MDBox sx={{ width: '50px', height: "50px" }}>
                  <NivoPieChart data={myData} innerRadius={0.6} />
                </MDBox>
              }
              {title === "Trade win %" &&
                <MDBox sx={{ width: '50px', height: "50px" }}>
                  <NivoHalfDoughnutChart
                    myData={[
                      {
                        id: "win",
                        label: "Win",
                        value: tradeWinPercentage,
                        color: "hsl(120, 70%, 50%)"
                      },
                      {
                        id: "loss",
                        label: "Loss",
                        value: 100 - tradeWinPercentage,
                        color: "hsl(0, 70%, 50%)"
                      }
                    ]}
                  />
                </MDBox>
              }
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCard
DefaultStatisticsCard.defaultProps = {
  percentage: {
    color: "success",
    value: "",
    label: "",
  },
  // dropdown: false,
};

// Typechecking props for the DefaultStatisticsCard
DefaultStatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  tradeWinPercentage: PropTypes.number,
  // dropdown: PropTypes.oneOfType([
  //   PropTypes.bool,
  //   PropTypes.shape({
  //     action: PropTypes.func,
  //     menu: PropTypes.node,
  //     value: PropTypes.string,
  //   }),
  // ]),
};

export default DefaultStatisticsCard;
