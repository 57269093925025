import React from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

function NoDataAvailable({ message, onShowDummyData }) {
  return (
    <MDBox 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="50vh"
      gap={3}
    >
      <Icon sx={{ fontSize: 60, color: 'text.secondary' }}>
        data_usage
      </Icon>
      <MDTypography variant="h5" color="text.secondary">
        {message || 'No Data Available'}
      </MDTypography>
      {onShowDummyData && (
        <MDButton 
          variant="contained" 
          color="info" 
          onClick={onShowDummyData}
        >
          View Dummy Data
        </MDButton>
      )}
    </MDBox>
  );
}

export default NoDataAvailable; 