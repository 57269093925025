/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { useRequestPasswordResetMutation, useResetPasswordMutation } from "features/user/userSlice";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { validateEmail, validatePassword } from "utils/validation";

function Cover() {
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const navigate = useNavigate();

  const [requestReset, { isLoading: isRequestingReset }] = useRequestPasswordResetMutation();
  const [resetPassword, { isLoading: isResettingPassword }] = useResetPasswordMutation();

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = (message, severity = 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setError(null);

    // Validate email
    const emailError = validateEmail(email);
    if (emailError) {
      setError(emailError);
      showSnackbar(emailError);
      return;
    }

    try {
      await requestReset({ email }).unwrap();
      setStep(2);
      showSnackbar('Verification code sent to your email', 'success');
    } catch (err) {
      const errorMessage = err.data?.message || 'Failed to send reset email';
      setError(errorMessage);
      showSnackbar(errorMessage);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError(null);

    // Validate password
    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setError(passwordError);
      showSnackbar(passwordError);
      return;
    }

    if (!verificationCode) {
      setError('Please enter the verification code');
      showSnackbar('Please enter the verification code');
      return;
    }

    try {
      const result = await resetPassword({
        email,
        code: verificationCode,
        newPassword
      }).unwrap();

      if (result.success) {
        showSnackbar('Password reset successfully', 'success');
        setTimeout(() => {
          navigate('/authentication/sign-in', {
            state: { message: 'Password reset successfully. Please login with your new password.' }
          });
        }, 1500);
      }
    } catch (err) {
      const errorMessage = err.data?.message || 'Failed to reset password';
      setError(errorMessage);
      showSnackbar(errorMessage);
    }
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {step === 1 
              ? "You will receive an email with instructions" 
              : "Enter the verification code and new password"}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            {step === 1 ? (
              <MDBox mb={4}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!error}
                />
              </MDBox>
            ) : (
              <>
                <MDBox mb={4}>
                  <MDInput
                    type="text"
                    label="Verification Code"
                    variant="standard"
                    fullWidth
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    error={!!error}
                  />
                </MDBox>
                <MDBox mb={4}>
                  <MDInput
                    type="password"
                    label="New Password"
                    variant="standard"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    error={!!error}
                  />
                </MDBox>
              </>
            )}
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={step === 1 ? handleRequestReset : handleResetPassword}
                disabled={isRequestingReset || isResettingPassword}
              >
                {(isRequestingReset || isResettingPassword) ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  step === 1 ? "Send Reset Link" : "Reset Password"
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </CoverLayout>
  );
}

export default Cover;
