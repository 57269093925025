import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import Switch from '@mui/material/Switch';
import { Snackbar, Alert } from '@mui/material';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import ShankyChart from "./components/ShankyChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import ReactDataTable from "examples/Tables/ReactDataTable";
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Calendar from "widgets/Calendar";
import { useGetPnlQuery } from "features/tradingApiSlice";
import { CircularProgress, FormControl, InputLabel, Select } from "@mui/material";
import { useGetTradingChargesQuery } from "features/tradingApiSlice";
import MDButton from "components/MDButton";
import { useGetUpstoxLoginQuery } from "features/tradingApiSlice";
import { useNavigate } from "react-router-dom";
import AppConfiguration from "./components/AppConfiguration";
import Cookies from "js-cookie";
import { useContext } from "react";
import { AppContext } from "components/context/AppContext";
import DataSelector from './components/DataSelector';
import DateRangeSelector from "./components/DateRangeSelector";
import NoDataAvailable from "./components/NoDataAvailable";

// Move helper functions before the component
const calculateMetrics = (trades) => {
  let localGrossProfit = 0;
  let localGrossLoss = 0;
  let winningTrades = 0;
  let totalWinAmount = 0;
  let totalLossAmount = 0;
  const totalTrades = trades.length;

  trades.forEach((trade) => {
    const profitOrLoss = trade.sell_amount - trade.buy_amount;
    if (profitOrLoss > 0) {
      localGrossProfit += profitOrLoss;
      totalWinAmount += profitOrLoss;
      winningTrades++;
    } else {
      localGrossLoss += Math.abs(profitOrLoss);
      totalLossAmount += Math.abs(profitOrLoss);
    }
  });

  const winRate = totalTrades > 0 ? winningTrades / totalTrades : 0;
  const lossRate = totalTrades > 0 ? (totalTrades - winningTrades) / totalTrades : 0;
  const averageWin = winningTrades > 0 ? totalWinAmount / winningTrades : 0;
  const averageLoss = (totalTrades - winningTrades) > 0 ? totalLossAmount / (totalTrades - winningTrades) : 0;

  const expectancy = (winRate * averageWin) - (lossRate * averageLoss);

  const netPnL = localGrossProfit - localGrossLoss;
  const profitFactor = localGrossLoss > 0
    ? (localGrossProfit / localGrossLoss).toFixed(2)
    : (localGrossProfit > 0 ? localGrossProfit.toFixed(2) : 0);

  const winPercentage = totalTrades > 0
    ? ((winningTrades / totalTrades) * 100).toFixed(2)
    : 0;

  return { netPnL, profitFactor, winPercentage, localGrossProfit, localGrossLoss, expectancy };
};

const processCalendarData = (trades) => {
  const calendarData = trades.reduce((acc, trade) => {
    if (trade.sell_date) {
      const sellDate = parseDateString(trade.sell_date);
      const pnl = Number(trade.sell_amount - trade.buy_amount);

      const formattedDate = sellDate;

      if (acc[formattedDate]) {
        acc[formattedDate].value = Number(acc[formattedDate].value) + pnl;
      } else {
        acc[formattedDate] = {
          day: formattedDate,
          value: pnl,
          colorValue: pnl >= 0 ? 1 : -1
        };
      }
    }
    return acc;
  }, {});

  return Object.values(calendarData).map(item => ({
    day: item.day,
    value: Number(item.value),
    colorValue: item.value >= 0 ? 1 : -1
  }));
};

const processTradeData = (trades) => {
  const dailyPnL = {};
  let cumulativePnL = 0;
  const cumulativePnLData = {};

  trades.forEach(trade => {
    const buyDate = parseDateString(trade.buy_date);
    const sellDate = parseDateString(trade.sell_date);
    const pnl = trade.sell_amount - trade.buy_amount;

    dailyPnL[buyDate] = (dailyPnL[buyDate] || 0) - pnl;
    dailyPnL[sellDate] = (dailyPnL[sellDate] || 0) + pnl;

    cumulativePnL += pnl;
    cumulativePnLData[sellDate] = cumulativePnL;
  });

  const allDates = [...new Set([...Object.keys(dailyPnL), ...Object.keys(cumulativePnLData)])].sort();

  const dailyData = allDates.map(date => dailyPnL[date] || 0);
  const cumulativeData = allDates.map(date => {
    const lastDate = allDates.slice(0, allDates.indexOf(date) + 1).filter(d => cumulativePnLData[d] !== undefined).pop();
    return lastDate ? cumulativePnLData[lastDate] : 0;
  });

  return {
    labels: allDates,
    dailyData,
    cumulativeData
  };
};

const parseDateString = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split('-');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Sales() {
  // Move all hooks to the top level
  const [salesDropdownValue, setSalesDropdownValue] = useState("6 May - 7 May");
  const [customersDropdownValue, setCustomersDropdownValue] = useState("6 May - 7 May");
  const [revenueDropdownValue, setRevenueDropdownValue] = useState("6 May - 7 May");
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);
  const [upstoxLogin, setUpstoxLogin] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [dataType, setDataType] = useState(null);
  const [isRealData, setIsRealData] = useState(localStorage.getItem('dataType') === 'real');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [lastUpdated, setLastUpdated] = useState(null);
  const [selectedRange, setSelectedRange] = useState('all');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [profitFactor, setProfitFactor] = useState(0);
  const [myData, setMyData] = useState([]);
  const [tradeWinPercentage, setTradeWinPercentage] = useState(0);
  const [netPnL, setNetPnL] = useState(0);
  const [dailyPnLData, setDailyPnLData] = useState({ labels: [], data: [] });
  const [cumulativePnLData, setCumulativePnLData] = useState({ labels: [], data: [] });
  const [calendarData, setCalendarData] = useState([]);
  const [provider, setProvider] = useState('');
  const [tradeExpectancy, setTradeExpectancy] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [value, setValue] = React.useState(0);
  const [selectedSegment, setSelectedSegment] = useState('ALL');

  // Move all query hooks to the top level
  const { data: upstoxLoginData } = useGetUpstoxLoginQuery('/upstox/login', {
    skip: !upstoxLogin
  });

  const { data: tradingChargesData, isLoading: isChargesLoading } = useGetTradingChargesQuery({
    url: `/upstox/tradeCharges?userId=${Cookies.get('user_id')}${selectedSegment !== 'ALL' ? `&segment=${selectedSegment}` : ''}`,
  });

  console.log("tradingChargesData faiz", tradingChargesData)

  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useContext(AppContext);

  // Update the useGetPnlQuery
  const { data, refetch, isLoading } = useGetPnlQuery({
    url: dataType === 'real'
      ? `/upstox/pnlData?userId=${Cookies.get('user_id')}&dateRange=${selectedRange}&segment=${selectedSegment}`
      : `/upstox/dummyPnlData?dateRange=${selectedRange}`,
    skip: isCheckingAuth
  });

  // Add function to check authentication status
  const checkAuthStatus = async () => {
    try {
      const userId = Cookies.get('user_id');
      if (!userId) {
        setIsCheckingAuth(false);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/upstox/getUpstoxAppConfiguration?userId=${userId}`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('access-token')}`
        }
      });

      const data = await response.json();

      if (data && data.accessToken) {
        setIsAuthenticated(true);
        setIsRealData(true);
        setDataType('real');
        localStorage.setItem('dataType', 'real');
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
    } finally {
      setIsCheckingAuth(false);
    }
  };

  // Move all useEffect hooks to the top level
  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    // Handle URL parameters after OAuth callback
    const params = new URLSearchParams(location.search);
    const status = params.get('status');
    const token = params.get('token');
    const expiry = params.get('expiry');

    if (status === 'success' && token && expiry) {
      // Set the cookie with proper expiry
      Cookies.set('upstox-access-token', token, {
        expires: new Date(expiry),
        sameSite: 'Lax'
      });

      // Clean up URL parameters
      window.history.replaceState({}, '', '/dashboards/sales');
    }
  }, [location]);

  useEffect(() => {
    if (Cookies.get('upstox-access-token')) {
      setDataType(isRealData ? 'real' : 'dummy');
    }
  }, [isRealData]);

  useEffect(() => {
    if (data && data.length > 0) {
      const { netPnL, profitFactor, winPercentage, localGrossProfit, localGrossLoss, expectancy } = calculateMetrics(data);
      setNetPnL(netPnL);
      setProfitFactor(profitFactor);
      setTradeWinPercentage(winPercentage);
      setTradeExpectancy(expectancy.toFixed(2));

      setMyData([
        {
          "id": "Gross Loss",
          "label": "Gross Loss",
          "value": Math.abs(localGrossLoss),
          "color": "hsl(114, 70%, 50%)"
        },
        {
          "id": "Gross Profit",
          "label": "Gross Profit",
          "value": Math.abs(localGrossProfit),
          "color": "hsl(0, 100%, 50%)"
        },
      ]);

      const { labels, dailyData, cumulativeData } = processTradeData(data);
      setDailyPnLData({ labels, data: dailyData });
      setCumulativePnLData({ labels, data: cumulativeData });

      const processedCalendarData = processCalendarData(data);
      setCalendarData(processedCalendarData);

      setLastUpdated(new Date());
    }
  }, [data]);

  // Show loading state while checking authentication
  if (isCheckingAuth) {
    return (
      <DashboardLayout>
        <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </MDBox>
      </DashboardLayout>
    );
  }

  if (!data) {
    return (
      <DashboardLayout>
        <MDBox display="flex" flexDirection="column" color="#1a1a1a" gap={3} justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress color="info" />
          <MDTypography variant="h6">Please wait while we are fetching your data</MDTypography>
        </MDBox>
      </DashboardLayout>
    );
  }

  // Add refresh handler
  const handleRefresh = async () => {
    setIsRefreshing(true);
    await refetch();
    setIsRefreshing(false);
    setLastUpdated(new Date());
    setSnackbar({
      open: true,
      message: 'Data refreshed successfully',
      severity: 'success'
    });
  };

  // console.log("datapp", data)
  // console.log("datapp type", dataType)

  // Calculate the percentage safely
  const calculatePercentage = () => {
    if (myData.length === 2 && (myData[0].value + myData[1].value) !== 0) {
      return ((netPnL / (myData[0].value + myData[1].value)) * 100).toFixed(2);
    }
    return "0.00";
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }) => setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );


  const verticalBarChartData = {
    labels: dailyPnLData.labels,
    datasets: [
      {
        label: "Net Daily P&L",
        color: "info",
        data: dailyPnLData.data,
      },
    ],
  };

  const gradientLineChartData = {
    labels: cumulativePnLData.labels,
    datasets: [
      {
        label: "Cumulative P&L",
        color: "info",
        data: cumulativePnLData.data,
      },
    ],
  };

  // Filter data for open and closed orders
  const openOrders = data?.filter(order => !order.sell_date) || [];
  const closedOrders = data?.filter(order => order.sell_date) || [];

  const handleDataTypeSelect = (type) => {
    setDataType(type);
  };

  const handleToggleData = async (event) => {
    const isChecked = event.target.checked;
    localStorage.setItem('dataType', isChecked ? 'real' : 'dummy');
    setIsRealData(isChecked);
    setDataType(isChecked ? 'real' : 'dummy');
    setSnackbar({
      open: true,
      message: `Switched to ${isChecked ? 'real' : 'dummy'} trading data`,
      severity: 'info'
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Show data selector if access token exists
  if (Cookies.get('upstox-access-token') && !dataType) {
    return (
      <DashboardLayout>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <DataSelector onSelectDataType={handleDataTypeSelect} />
        </MDBox>
      </DashboardLayout>
    );
  }

  const handleUpstoxLogin = async (event) => {
    event.preventDefault();
    setUpstoxLogin(true);
  };

  const handleLogout = async (event) => {
    event.preventDefault();

    // Onlogout remove the access token and refresh token from the cookie and database 
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
      method: 'POST',
      body: JSON.stringify({ userId: currentUser.id }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    if (data.message === "Logged out successfully") {
      // remove the access token and refresh token from the cookie
      Cookies.remove('access-token')
      Cookies.remove('refresh-token')
      Cookies.remove('user_id')
      Cookies.remove('upstox-access-token')
      setCurrentUser(null);
      navigate('/sign-in')
    }


  }
  const handleProviderChange = (event) => {
    setProvider(event.target.value);
    // Always show config when provider is selected
    setShowConfig(true);
  };


  const handleShowDummyData = () => {
    setIsRealData(false);
    setDataType('dummy');
    setSnackbar({
      open: true,
      message: 'Switched to dummy trading data',
      severity: 'info'
    });
  };

  // Add this function to handle empty data cases
  const handleDateRangeChange = async (range) => {
    setSelectedRange(range);
    setIsRefreshing(true);

    console.log("range", range)
    try {
      const result = await refetch();

      if (result.data?.data?.length === 0) {
        setSnackbar({
          open: true,
          message: result.data?.message || `No trading data available for the selected ${range} range`,
          severity: 'info'
        });
      } else {
        setSnackbar({
          open: true,
          message: `Data updated for ${range === 'all' ? 'all time' : `last ${range}`}`,
          severity: 'success'
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error fetching data for the selected range',
        severity: 'error'
      });
    } finally {
      setIsRefreshing(false);
      setLastUpdated(new Date());
    }
  };

  // Add segment change handler
  const handleSegmentChange = async (event) => {
    setSelectedSegment(event.target.value);
    setIsRefreshing(true);
    try {
      await refetch();
      setSnackbar({
        open: true,
        message: `Data updated for ${event.target.value === 'ALL' ? 'all segments' : `segment ${event.target.value}`}`,
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error fetching data for the selected segment',
        severity: 'error'
      });
    } finally {
      setIsRefreshing(false);
      setLastUpdated(new Date());
    }
  };

  // Add this near the top of the component with other styles
  const stickyBarStyle = {
    width: '30%',
    margin: 'auto',
    borderRadius: '8px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffa726', // Orange warning color
    color: 'white',
    textAlign: 'center',
    padding: '8px',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px'
  };

  // Update the navbar section to include connection status
  const renderNavbar = () => (
    <MDBox py={.25} mb={1}>
      <MDBox sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 .25rem",
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.primary,
        boxShadow: (theme) => theme.shadows[4],
        borderRadius: "8px",
        height: "50px",
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* <MDTypography variant="h6">
            {isRealData ? "Real Trade Data" : "Dummy Trade Data"}
          </MDTypography> */}
          {lastUpdated && (
            <MDTypography variant="caption" color="text">
              Last updated: {new Date(lastUpdated).toLocaleTimeString()}
              <IconButton
                size="small"
                onClick={handleRefresh}
                disabled={isRefreshing}
                sx={{
                  ml: 1,
                  animation: isRefreshing ? 'spin 1s linear infinite' : 'none'
                }}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </MDTypography>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Data toggle switch */}
          <Tooltip title="Switch between real and dummy trading data" arrow placement="bottom">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <MDTypography variant="caption" fontWeight="regular">
                Dummy
              </MDTypography>
              <Switch
                checked={isRealData}
                onChange={handleToggleData}
                color="success"
              />
              <MDTypography variant="caption" fontWeight="regular">
                Real
              </MDTypography>
            </Box>
          </Tooltip>

          {/* Provider selection */}
          <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth>
              <InputLabel sx={{ fontSize: ".7em", fontWeight: 'bold' }}>
                Select Providers
              </InputLabel>
              <Select
                value={provider}
                label="Select Providers"
                onChange={handleProviderChange}
                sx={{ height: "40px" }}
              >
                <MenuItem value="upstox">Upstox</MenuItem>
                <MenuItem value="zerodha" disabled>Zerodha</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Segment Filter */}
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel sx={{ fontSize: ".7em", fontWeight: 'bold' }}>
                Segment
              </InputLabel>
              <Select
                value={selectedSegment}
                label="Segment"
                onChange={handleSegmentChange}
                sx={{ height: "40px" }}
              >
                <MenuItem value="ALL">All Segments</MenuItem>
                <MenuItem value="EQ">Equity</MenuItem>
                <MenuItem value="FUT-OPT">F&O</MenuItem>
                <MenuItem value="CD">Currency</MenuItem>
                <MenuItem value="COM">Commodity</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Date Range Selector */}
          <DateRangeSelector
            selectedRange={selectedRange}
            onRangeChange={handleDateRangeChange}
          />

          {/* Logout button */}
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            sx={{ width: "70px" }}
            onClick={handleLogout}
          >
            Logout
          </MDButton>
        </Box>
      </MDBox>
    </MDBox>
  );

  if (data.length === 0) {
    return (
      <DashboardLayout>
        {showConfig && (
          <AppConfiguration
            provider={provider}
            onClose={() => setShowConfig(false)}
          />
        )}
        {renderNavbar()}
        <NoDataAvailable
          message={data?.message || "No trading data available"}
          onShowDummyData={!isRealData ? null : handleShowDummyData}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </DashboardLayout>
    );
  }


  return (
    <DashboardLayout>
      {renderNavbar()}
      <MDBox>
        <MDBox mb={1}>
          <Grid container spacing={1}>
            {/* Net P & L */}
            <Grid item xs={12} sm={3}>
              <DefaultStatisticsCard
                title="Net P&L"
                count={`${netPnL.toFixed(2)}`}
                myData={myData}
                percentage={{
                  color: netPnL > 0 ? "success" : "error",
                  value: `${calculatePercentage()}%`,
                  label: "overall performance",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            {/* Trade Expectancy */}
            <Grid item xs={12} sm={3}>
              <DefaultStatisticsCard
                title="Trade Expectancy"
                count={tradeExpectancy}
                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
              />
            </Grid>
            {/* Profit Factor */}
            <Grid item xs={12} sm={3}>
              <DefaultStatisticsCard
                title="Profit Factor"
                count={profitFactor}
                myData={myData}
                // percentage={{
                //   color: "secondary",
                //   value: "+$213",
                //   label: "since last month",
                // }}
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
              />
            </Grid>
            {/* Trade win % */}
            <Grid item xs={12} sm={3}>
              <DefaultStatisticsCard
                title="Trade win %"
                count={`${tradeWinPercentage}%`}
                tradeWinPercentage={parseFloat(tradeWinPercentage)}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={1}>
          <Grid container spacing={1}>
            {/* Shanky Chart */}
            <Grid item xs={12} sm={6} lg={4}>
              <ShankyChart tradingCharges={tradingChargesData} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {/* Daily net Cumulative P&L */}
              <GradientLineChart
                title="Daily net Cumulative P&L"
                description="Cumulative Profit and Loss over time"
                chart={gradientLineChartData}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {/* Net daily P&L */}
              <VerticalBarChart
                title="Net daily P&L"
                chart={verticalBarChartData}
              />
            </Grid>

            <Grid item xs={12} sm={12} lg={12}>
              <Card>  
                <MDBox pt={2} pr={2} pl={2}>
                  <MDBox display="flex" px={1} pt={1}>
                    <MDTypography variant="h6">Calendar</MDTypography>
                  </MDBox>
                <MDBox sx={{ height: "26rem", overflowY: "scroll" }}>
                  <Calendar data={calendarData} />
                </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Card>
                <MDBox pt={2} pr={2} pl={2}>
                  <MDBox display="flex" px={1} pt={1}>
                    <MDTypography variant="h6">Order</MDTypography>
                  </MDBox>
                  <MDBox>
                    <Box sx={{ width: '100%', height: '100%' }}>
                      <Box sx={{ marginBottom: 1 }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab sx={{ fontSize: ".7em", fontWeight: 'bold' }} label="Open" {...a11yProps(0)} />
                          <Tab sx={{ fontSize: ".7em", fontWeight: 'bold' }} label="Close" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={value} index={0}>
                        <ReactDataTable data={openOrders} />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1}>
                        <ReactDataTable data={closedOrders} />
                      </CustomTabPanel>
                    </Box>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {showConfig && (
        <AppConfiguration
          provider={provider}
          onClose={() => setShowConfig(false)}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {!isRealData && (
        <MDBox sx={stickyBarStyle}>
          <MDTypography variant="button" fontWeight="medium" color="white">
            You are viewing dummy trading data
          </MDTypography>
          {Cookies.get('upstox-access-token') && (
            <MDButton
              variant="contained"
              color="success"
              size="small"
              onClick={() => {
                setIsRealData(true);
                setDataType('real');
              }}
            >
              Switch to Real Data
            </MDButton>
          )}
        </MDBox>
      )}

      <MDBox sx={{ height: '4rem' }}>
        <Footer />
      </MDBox>

      {/* Add the spinning animation style */}
      <style>
        {`
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </DashboardLayout>
  );
}

export default Sales;

