/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
// import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import bgImage2 from "../../../assets/images/trading/login_bg.jpg";

import { useNavigate } from "react-router-dom";
import { useSignInMutation } from "features/user/userSlice";
import Cookies from "js-cookie";
import { useContext } from "react";
import { AppContext } from "components/context/AppContext";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { validateEmail, validatePassword } from "utils/validation";

function Illustration() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { setCurrentUser, currentUser } = useContext(AppContext);
  const [signIn, { isLoading }] = useSignInMutation();
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState(location.state?.message || null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Redirect if user is already logged in
  useEffect(() => {
    if (currentUser) {
      navigate('/dashboards/sales');
    }
  }, [currentUser, navigate]);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = (message, severity = 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError(null);

    // Validate inputs
    const emailError = validateEmail(email);
    if (emailError) {
      setError(emailError);
      showSnackbar(emailError);
      return;
    }

    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      showSnackbar(passwordError);
      return;
    }

    try {
      const result = await signIn({
        email,
        password
      }).unwrap();

      if (result.refreshToken) {
        const oneDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        Cookies.set("refresh-token", result.refreshToken, {
          secure: true,
        });
        Cookies.set("access-token", result.accessToken, {
          secure: true,
          expires: oneDay
        });
        Cookies.set("user_id", result.id, {
          secure: true,
        });
      }

      console.log("result", result)

      if (result) {
        setCurrentUser(result);
        navigate('/dashboards/sales');
        showSnackbar('Successfully signed in', 'success');
      }
    } catch (err) {
      console.log("err", err)
      const errorMessage = err.data?.message || 'An error occurred during sign in';
      setError(errorMessage);
      showSnackbar(errorMessage);
    }
  };

  useEffect(() => {
    // Clear success message after 5 seconds
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={bgImage2}
    >
      <MDBox component="form" role="form">
        {/* Show success message if present */}
        {successMessage && (
          <MDBox mb={2}>
            <MDTypography variant="caption" color="success" fontSize={12} fontWeight="medium">
              {successMessage}
            </MDTypography>
          </MDBox>
        )}
        
        <MDBox mb={2}>
          <MDInput type="email" label="Email" fullWidth onChange={handleEmailChange} />
        </MDBox>
        <MDBox mb={2}>
          <MDInput type="password" label="Password" fullWidth onChange={handlePasswordChange} />
        </MDBox>
        {/* <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox> */}
        {/* Error handling */}
        {error && (
          <MDBox mb={2}>
            <MDTypography variant="caption" color="error" fontSize={12}>
              {error}
            </MDTypography>
          </MDBox>
        )}
        <MDBox mt={4} mb={1}>
          <MDButton 
            variant="gradient" 
            color="info" 
            size="large" 
            fullWidth 
            onClick={handleSignIn}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'sign in'
            )}
          </MDButton>
        </MDBox>
        {/* Reset password */}
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Forgot your password?{" "}
            <MDTypography
              component={Link}
              to="/authentication/reset-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Reset password
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </IllustrationLayout>
  );
}

export default Illustration;
