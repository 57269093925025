import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import nodeService from "../services/nodeServices";

export const tradingApi = createApi({
    reducerPath: "tradingApi",
    baseQuery: async (args) => {
        // console.log("args", args)
        try {
            if (typeof args == "string") {
                const result = await nodeService(args);
                return result;
            } else {
                const { url, method, body } = args;
                const result = await nodeService({ url, method, data: body });
                return result;
            }
        } catch (axiosError) {
            return { error: axiosError.response?.data || axiosError.message };
        }
    },
    endpoints: (builder) => ({
        getPnl: builder.query({
            query: ({ url, dateRange }) => {
                const baseUrl = url;
                const urlWithParams = dateRange && dateRange !== 'all'
                    ? `${baseUrl}&dateRange=${dateRange}`
                    : baseUrl;
                return urlWithParams;
            },
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
        }),
        getTradingCharges: builder.query({
            query: (getDataURL) => getDataURL,
            transformResponse: (response) => response.data,
            transformErrorResponse: (response) => response.status,
        }),
            getUpstoxLogin: builder.query({
                query: (getDataURL) => getDataURL,
                transformResponse: (response) => response.data,
                transformErrorResponse: (response) => response.status,
            }),
        }),
    });

    export const {
        useGetPnlQuery,
        useGetTradingChargesQuery,
        useGetUpstoxLoginQuery
    } = tradingApi;
